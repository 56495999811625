















































import { computed, defineComponent } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import ErrorAlert from "@/components/ErrorAlert.vue";
import Alert from "@/lib/pfg/vue2/alert/Alert.vue";
import AlertDescription from "@/lib/pfg/vue2/alert/AlertDescription.vue";
import AlertTitle from "@/lib/pfg/vue2/alert/AlertTitle.vue";
import { queries } from "@/lib/query/queries";
import Item from "./Item.vue";

export default defineComponent({
	components: {
		ErrorAlert,
		Alert,
		AlertTitle,
		AlertDescription,
		Item,
	},
	props: {
		memberId: {
			type: Number,
			required: true,
		},
	},
	setup(props) {
		const { status, data, error } = useQuery({
			...queries.member(props.memberId).personalTraining.all(),
			queryKey: computed(() => queries.member(props.memberId).personalTraining.all().queryKey),
		});

		return { props, status, data, error };
	},
});
