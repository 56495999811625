

















































import { defineComponent } from "@vue/composition-api";
import { useQuery, useQueryClient } from "@tanstack/vue-query";
import { reserveerApi } from "@/lib/backend";
import type { IomodelsFitnessAfspraakExtern } from "@/lib/backend/reserveer.api";
import { APPOINTMENT_MAP } from "@/lib/constants/options";
import { logger } from "@/logger";
import { usePopupStore } from "@/pinia/popup";
import {
	getDateFromDatum,
	getDayMonth,
	getDayMonthYear,
	getTimeOfDay,
	getWeekDay,
} from "@/utils/date";
import ErrorAlert from "../ErrorAlert.vue";

export default defineComponent({
	components: { ErrorAlert },
	props: {
		memberId: {
			type: Number,
			required: true,
		},
	},
	setup(props) {
		const queryClient = useQueryClient();
		const popupStore = usePopupStore();

		const { status, data, error } = useQuery({
			queryKey: ["member", props.memberId, "appointments", "aankomend"] as const,
			queryFn: async (context) =>
				await reserveerApi.fitnessafspraken
					.getAfsprakenVoorLid(
						context.queryKey[1],
						{ status: context.queryKey[3] },
						{ signal: context.signal },
					)
					.then((response) => response.data),
		});

		function openOpmerking(afspraak: IomodelsFitnessAfspraakExtern) {
			popupStore.open({
				title: "Opmerking",
				body: afspraak.aanwezigheid?.opmerkingen || "",
				buttons: {
					cancel: "Sluiten",
				},
			});
		}

		function cancelPopup(afspraak: IomodelsFitnessAfspraakExtern) {
			popupStore.open({
				title: "Let op!",
				body: `Weet je zeker dat je de afspraak wilt annuleren voor <b>${afspraak.aanwezigheid?.lidNaam}</b> op <b>${getDayMonthYear(new Date(afspraak.datum))}</b>?`,
				buttons: {
					cancel: "Sluiten",
					confirm: "Afspraak annuleren",
				},
				callback: async () => {
					await cancelAfspraak(afspraak);
				},
			});
		}

		async function cancelAfspraak(afspraak: IomodelsFitnessAfspraakExtern) {
			try {
				const aanwezigheid = afspraak.aanwezigheid;

				if (!aanwezigheid) {
					throw new Error("Geen afspraak aanwezigheid");
				}

				const response = await reserveerApi.fitnessafspraken.updateAanwezigheid(afspraak.id, {
					...aanwezigheid,
					status: "afgemeld",
				});

				switch (response.status) {
					case 200: {
						queryClient.refetchQueries({ queryKey: ["member", props.memberId, "appointments"] });

						return;
					}

					default: {
						throw response;
					}
				}
			} catch (error: { message: string } | string | unknown) {
				logger.error("Cancel Appointment failed", error);

				const message = (error as { message: string })?.message || error;

				popupStore.showError(
					`Er ging iets mis bij het annuleren van de afspraak: ${message}.<br/>Probeer het later nog eens.`,
				);
			}
		}

		function getAppointmentText({ aanwezigheid }: IomodelsFitnessAfspraakExtern): string {
			if (!aanwezigheid) return "";

			const values = Object.entries(aanwezigheid)
				.filter(([key]) => Object.keys(APPOINTMENT_MAP).includes(key))
				.filter(([, value]) => value === true)
				.map(([key]) => APPOINTMENT_MAP[key].shortText);

			if (values.length === 0) return "";

			// eslint-disable-next-line
			// @ts-ignore - ListFormat is not correctly typed within compiler TS
			return new Intl.ListFormat("nl", {
				style: "long",
				type: "conjunction",
			}).format(values);
		}

		return {
			status,
			data,
			error,
			getWeekDay,
			getDayMonth,
			getTimeOfDay,
			getDateFromDatum,
			cancelAfspraak,
			openOpmerking,
			cancelPopup,
			getAppointmentText,
		};
	},
});
